import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireList, AngularFireDatabase } from '@angular/fire/database';
import { AuthenticationService, CredentialsService, Credentials } from '@app/core';
import {
  ActionSheetController,
  AlertController,
  LoadingController,
  ModalController,
  Platform,
  ToastController
} from '@ionic/angular';
import { UserComponent } from '@app/shared/user/user.component';
import { CirclesearchComponent } from '@app/shared/circlesearch/circlesearch.component';
import { UsersearchComponent } from '@app/shared/usersearch/usersearch.component';
import { DataService } from '@app/shared/services/data/data.service';
import { Emergency } from './../core/db/emergency.model';
import { Circle } from './../core/db/circle.model';
import { Observable, Subject, Subscription } from 'rxjs';
import { environment } from '@env/environment';
import { map, take } from 'rxjs/operators';
import * as geohash from 'ngeohash';
import * as firebase from 'firebase';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent {
  version: string | null = environment.version;
  active: boolean;
  alarmState = false;
  pendingFollow = false;
  emCol: AngularFirestoreCollection<Emergency>;
  emergencies: Emergency[] = [];
  emergenciesTmp: Observable<Emergency[]>;
  follows: any[] = [];
  followsTmp: Observable<Emergency[]>;
  user: Credentials = {
    username: '',
    token: '',
    role: '',
    domain: '',
    group: ''
  };
  guardian: any;
  alert: HTMLIonAlertElement;
  sendEmergencies = new Subject<boolean>();
  sendEmergencies$ = this.sendEmergencies.asObservable();
  sendCircles = new Subject<boolean>();
  sendCircles$ = this.sendCircles.asObservable();
  sendFollows = new Subject<boolean>();
  sendFollows$ = this.sendFollows.asObservable();
  emergencySub$: Subscription;
  followSub$: Subscription;
  permissions:
    | {
        status: boolean;
      }[]
    | {
        name: string;
        title: string;
        description: string;
        status: any;
        icon: string;
      }[] = [];
  pendingPermissions: any = [];
  circles: Array<any> = [];
  initialized: boolean = false;
  activeEmergencies = false;
  activeFollows: boolean = false;
  users: any[] = [];
  env = environment;
  geofences: any[] = [];
  into: boolean = false;
  search: boolean = false;
  search_timeout: any;
  tmpPhones: any[] = [];
  presentDiatel: boolean = false;
  switch: boolean = true;
  crudUsers: boolean = false;

  constructor(
    private router: Router,
    public platform: Platform,
    private alertController: AlertController,
    private modalController: ModalController,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private authenticationService: AuthenticationService,
    private afs: AngularFirestore,
    public afd: AngularFireDatabase,
    private credentialsService: CredentialsService,
    public dataService: DataService
  ) {
    this.user = this.credentialsService.credentials;
    this.init();
  }

  async init() {
    const dbcall = await this.afs.doc('wrUsers/' + this.user.token);
    await dbcall.valueChanges().subscribe(data => {
      this.guardian = data;
      const cred = {
        username: this.guardian.username,
        token: this.credentialsService.credentials.token,
        domain: this.guardian.domain ? this.guardian.domain : this.credentialsService.credentials.domain,
        role: this.guardian ? this.guardian.role : this.user.role ? this.user.role : 'guardian',
        group: this.guardian.group ? this.guardian.group : environment.GROUP != '' ? environment.GROUP : ''
      };

      if (this.credentialsService.credentials.role != cred.role) {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }

      this.credentialsService.setCredentials(cred, true);
      this.user = this.credentialsService.credentials;

      this.initServices();
    });

    try {
      /* this.checkPermissions(); */
    } catch (error) {}
  }

  /* async checkPermissions() {
    this.permissions = [
      {
        name: 'mic',
        title: 'Micrófono',
        description: 'Usar micrófono para grabar audio en caso de emergencia',
        status: false,
        icon: 'mic'
      },
      {
        name: 'camera',
        title: 'Cámara',
        description: 'Usar cámara para iniciar una videollamada en caso de emergencia',
        status: false,
        icon: 'videocam'
      }
    ];

    //check mic permissions
    let status = await this.diagnostic.getMicrophoneAuthorizationStatus();
    this.savePermission('mic', status);

    if (status === this.diagnostic.permissionStatus.GRANTED) {
      this.permissions[this.permissions.findIndex(x => x.name === 'mic')].status = true;
    }
    //check camera permissions
    status = await this.diagnostic.getCameraAuthorizationStatus();
    this.savePermission('camera', status);

    if (status === this.diagnostic.permissionStatus.GRANTED) {
      this.permissions[this.permissions.findIndex(x => x.name === 'camera')].status = true;
    }

    this.askPermissions();
  } */

  async askPermissions() {
    for (let index = 0; index < this.permissions.length; index++) {
      const element: any = this.permissions[index];
      /* await this.requestPermission(element.name); */
    }
  }

  /* async requestPermission(permission: string) {
    let msgRequired = 'Permiso requerido';
    let msgProperly = 'Es necesario que otorgues el permiso para el correcto funcionamiento de la aplicación';
    let msgProperlyAlwaysiOS = 'Selecciona "Siempre" para el correcto funcionamiento de la aplicación';
    let msgDeniediOS =
      'Seleccionaste "Nunca". Ingresa a Configuración>' + environment.APP_NAME + ' y otorga permiso para utilizar ';
    let msgDeniedAlwaysAndroid =
      'Seleccionaste "No preguntar de nuevo". Ingresa a Ajustes>Aplicaciones>' +
      environment.APP_NAME +
      ' y otorga permiso para utilizar ';
    let requireError = 'Error al solicitar permiso para utilizar ';
    let msgDefault = 'Dato desconocido';

    let permissionIndex = this.permissions.findIndex((x: any) => x.name == permission);
    let status: any;

    try {
      switch (permission) {
        case 'mic':
          status = await this.diagnostic.requestMicrophoneAuthorization();
          await this.savePermission('mic', status);

          switch (status) {
            case this.diagnostic.permissionStatus.GRANTED:
              this.permissions[permissionIndex].status = true;
              break;
            case this.diagnostic.permissionStatus.DENIED:
              this.permissions[permissionIndex].status = false;

              if (this.platform.is('ios')) {
                this.actionShowAlert(msgRequired, msgDeniediOS + 'Micrófono', 'settings');
              } else if (this.platform.is('android')) {
                this.showAlert(msgRequired, msgProperly);
              }
              break;
            case this.diagnostic.permissionStatus.DENIED_ALWAYS:
              this.permissions[permissionIndex].status = false;
              this.showAlert(msgRequired, msgDeniedAlwaysAndroid + 'Micrófono');
              break;
            default:
              this.permissions[permissionIndex].status = false;
              break;
          }
          break;

        case 'camera':
          status = await this.diagnostic.requestCameraAuthorization();
          await this.savePermission('camera', status);

          switch (status) {
            case this.diagnostic.permissionStatus.GRANTED:
              this.permissions[permissionIndex].status = true;
              break;
            case this.diagnostic.permissionStatus.DENIED:
              this.permissions[permissionIndex].status = false;

              if (this.platform.is('ios')) {
                this.actionShowAlert(msgRequired, msgDeniediOS + 'Cámara', 'settings');
              } else if (this.platform.is('android')) {
                this.showAlert(msgRequired, msgProperly);
              }
              break;
            case this.diagnostic.permissionStatus.DENIED_ALWAYS:
              this.permissions[permissionIndex].status = false;
              this.showAlert(msgRequired, msgDeniedAlwaysAndroid + 'Cámara');
              break;
            default:
              this.permissions[permissionIndex].status = false;
              break;
          }
          break;

        case 'notifications':
          //request notifications permission
          status = await this.diagnostic.getRemoteNotificationsAuthorizationStatus();
          await this.savePermission('notifications', status);
          if (status !== this.diagnostic.permissionStatus.GRANTED) {
            this.permissions[permissionIndex].status = false;
            this.actionShowAlert(
              msgRequired,
              'Ingresa a Configuración>' + environment.APP_NAME + '>Notificaciones y activa "Permitir notificaciones"',
              'notification_id'
            );
          }
          break;

        default:
          this.permissions[permissionIndex].status = false;
          break;
      }
    } catch (error) {
      this.showAlert('Atención', 'error al solicitar permiso.' + error);
      console.log(error);
    }
  } */

  async savePermission(permission: string, status: string) {
    /* try {
      let permissionStatus = {};
      permissionStatus[permission] = status;
      await this.fireProvider.updateItem(
        "devices/" + this.uid + "/permissions",
        permissionStatus
      );
    } catch (error) {
      console.log(error);
    } */
  }

  async presentPersonPrompt() {
    this.alert = await this.alertController.create({
      header: 'Código de la persona a mostrar:',
      inputs: [
        {
          name: 'uuid',
          type: 'text',
          placeholder: 'uuid'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        },
        {
          text: 'Ok',
          handler: (data: any) => {
            const uid: string = data.uuid.toUpperCase();
            this.getUserSearch(uid);
          }
        }
      ]
    });

    await this.alert.present();
  }

  async getUserSearch(uid: string) {
    const loading = await this.loadingController.create({
      message: 'Buscando...'
    });

    await loading.present();

    this.afd
      .list('users', ref =>
        ref
          .orderByChild('username')
          .equalTo(uid)
          .limitToFirst(1)
      )
      .valueChanges()
      .pipe(take(1))
      .subscribe(data => {
        let showError = false;

        loading.dismiss();

        if (data.length > 0) {
          const user: any = data[0];

          if (environment.GROUP == '' && this.credentialsService.credentials.group == '') {
            this.router.navigate([`/person/${uid}`]);
          } else {
            const circleIndex: number = this.circles.findIndex(x => {
              return x.key === user.cid;
            });

            if (circleIndex > -1) {
              this.router.navigate([`/person/${uid}`]);
            } else {
              showError = true;
            }
          }
        } else {
          showError = true;
        }

        if (showError) this.showAlert('Usuario no encontrado', 'Por favor intenta nuevamente');
      });
  }

  async presentGroupPrompt() {
    this.alert = await this.alertController.create({
      header: 'Código del círculo a mostrar:',
      inputs: [
        {
          name: 'uuid',
          type: 'text',
          placeholder: 'uuid'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        },
        {
          text: 'Ok',
          handler: (data: any) => {
            const cid: string = data.uuid.toUpperCase();

            if (environment.GROUP == '' && this.credentialsService.credentials.group == '') {
              this.router.navigate(['/circle/' + cid]);
            } else {
              const circleIndex: number = this.circles.findIndex(x => {
                return x.key === cid;
              });

              if (circleIndex > -1) {
                this.router.navigate(['/circle/' + cid]);
              }
            }
            console.log('Confirm Ok');
          }
        }
      ]
    });

    await this.alert.present();
  }

  async presentFollowPrompt(start: any, end: any, uid: any, username: any, key: any) {
    this.alert = await this.alertController.create({
      header: '¿Aceptar petición de Follow Me?',
      message:
        '<strong>Usuario: </strong>' +
        username +
        ' <br>' +
        '<strong>Inicio: </strong>' +
        start.toDate('MM/d/yyyy') +
        ' <br>' +
        '<strong>Fin: </strong>' +
        end.toDate() +
        ' <br>',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        },
        {
          text: 'Ok',
          handler: (data: any) => {
            this.router.navigate([
              '/followme/' + key,
              {
                accept: true
              }
            ]);
          }
        }
      ]
    });

    await this.alert.present();
  }

  async shutDownAlarm() {
    const group = environment.GROUP != '' ? environment.GROUP : this.user.group;

    switch (group) {
      case 'sumitomo':
        this.listView(true);
        this.alarmState = !this.alarmState;
        this.router.navigate(['/home']);

        break;
      default:
        /* const alert = await this.alertController.create({
          header: 'Confirmar!',
          message: '¿Quieres apagar la alarma?',
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {}
            },
            {
              text: 'Ok',
              handler: () => {
                this.alarmState = !this.alarmState;
              }
            }
          ]
        });
        await alert.present(); */
        this.alarmState = !this.alarmState;
        break;
    }
  }

  async shutDownAlert() {
    const group = environment.GROUP != '' ? environment.GROUP : this.user.group;

    switch (group) {
      default:
        this.listView(false);
        this.router.navigate(['/home']);
        this.pendingFollow = !this.pendingFollow;

        break;
      case 'sumitomo':
        this.pendingFollow = !this.pendingFollow;

        /* const alert = await this.alertController.create({
          header: 'Confirmar!',
          message: '¿Quieres apagar la alarma?',
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {}
            },
            {
              text: 'Ok',
              handler: () => {
                this.pendingFollow = !this.pendingFollow;
              }
            }
          ]
        });
        await alert.present(); */
        break;
    }
  }

  async logout() {
    this.alarmState = false;
    const alert = await this.alertController.create({
      header: 'Salir',
      message: '¿Desea cerrar sesión?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        },
        {
          text: 'Ok',
          handler: () => {
            this.authenticationService.logout();
            this.router.navigate(['/login']);
            this.emergencySub$.unsubscribe();
          }
        }
      ]
    });
    await alert.present();
  }

  async initServices() {
    if (environment.GROUP == '' && this.credentialsService.credentials.group == '') {
      this.subscribeEmergency(false);
      this.subscribeFollow(false);
      this.subscribeGeofences(false);
    } else {
      const group = this.credentialsService.credentials.group;

      this.afs
        .collection('circles', ref => ref.where('group', '==', group))
        .snapshotChanges()
        .subscribe(circles => {
          const corporateCircles = circles.map(circle => {
            const circleData: any = circle.payload.doc.data();
            circleData.key = circle.payload.doc.id;

            return circleData;
          });

          this.circles = corporateCircles;
          this.dataService.circles = this.circles;

          if (this.initialized) {
            try {
              this.emergencySub$.unsubscribe();
              this.emergencySub$ = null;

              this.followSub$.unsubscribe();
              this.followSub$ = null;
            } catch (error) {}
          }

          this.initialized = true;
          this.subscribeEmergency(true);

          if (this.user.role == 'admin') {
            this.subscribeFollow(true);
            this.subscribeGeofences(true);
          }

          this.sendCircles.next();
        });

      this.dataService.videoEnable(group);
      const collective = await this.dataService.getCollective(group);
      if ('crudUsers' in collective) {
        this.crudUsers = Boolean(collective.crudUsers);
      }
    }
  }

  subscribeGeofences(group: boolean) {
    this.into = false;
    this.geofences = [];

    let geofences;
    let subscribe = true;
    if (group) {
      geofences = this.afs.collection<Emergency>('posts', ref =>
        ref
          .where('geofence', '==', true)
          .where('risk_zone', '==', true)
          .where('action', '==', 1)
          .orderBy('published')
          .where('group', '==', this.credentialsService.credentials.group)
          .limitToLast(1)
      );
    } else {
      geofences = this.afs.collection<Emergency>('posts', ref =>
        ref
          .where('geofence', '==', true)
          .where('risk_zone', '==', true)
          .where('action', '==', 1)
          .orderBy('published')
          .where('group', '==', null)
          .limitToLast(1)
      );
      subscribe = false;
    }
    if (subscribe) {
      this.emergencySub$ = geofences.snapshotChanges().subscribe(async actions => {
        await actions.map(async a => {
          const data = a.payload.doc.data() as Emergency;
          data.key = a.payload.doc.id;

          const circleIndex: number = this.circles.findIndex(x => {
            return x.key === data.circle;
          });

          if (!group || (group && circleIndex > -1)) {
            switch (this.user.role) {
              case 'admin':
                await this.addGeofence(data);
                break;
              case 'guardian':
                const index: number = this.guardian.groups
                  ? this.guardian.groups.findIndex((x: string) => {
                      return x === data.circle;
                    })
                  : -1;

                if (index != -1 || !this.guardian.groups) {
                  //show inside and outside
                  await this.addGeofence(data);
                }
                break;
            }
          }
        });

        this.into = true;
      });
    }
  }

  addEmergency(data: Emergency) {
    // Busca si el objeto ya existe antes de meterlo al arreglo
    const index: number = this.emergencies.findIndex(x => {
      return x.key == data.key;
    });

    if (index > -1) {
      // Si existe, actualiza objeto
      this.emergencies[index] = data;
    } else {
      // No existe, inserta objeto
      this.emergencies.push(data);
      this.emergencies.sort((c, d) => d.published.toDate() - c.published.toDate());

      this.checkAlarm(data);
    }
  }

  addFollow(data: any) {
    // Busca si el objeto ya existe antes de meterlo al arreglo
    const index: number = this.follows.findIndex(x => {
      return x.key === data.key;
    });

    if (index > -1) {
      // Si existe, actualiza objeto
      this.follows[index] = data;
    } else {
      // No existe, inserta objeto
      this.follows.push(data);
      this.follows.sort((c, d) => d.published.toDate() - c.published.toDate());

      this.checkFollowAlarm(data);
    }
  }

  async addGeofence(data: Emergency) {
    // Busca si el objeto ya existe antes de meterlo al arreglo
    const index: number = this.geofences.findIndex((x: { key: string }) => {
      return x.key == data.key;
    });

    if (index > -1) {
      // Si existe, actualiza objeto
      this.geofences[index] = data;
    } else {
      // No existe, inserta objeto
      this.geofences.push(data);
      this.geofences.sort((c: any, d: any) => d.published.toDate() - c.published.toDate());

      try {
        await this.geofenceAlarm(data);
      } catch (error) {}
    }
  }

  checkAlarm(data: Emergency) {
    if (!data.emergencySolved) {
      this.active = true;
    }

    if (this.active) {
      this.alarmState = true;
    } else {
      if (this.user.role != 'admin') {
        this.alarmState = false;
      }
    }
  }

  checkFollowAlarm(data: any) {
    if (!data.finished) {
      this.activeFollows = true;
    }

    if (this.activeFollows) {
      this.pendingFollow = true;
    } else {
      if (this.user.role != 'admin') {
        this.pendingFollow = false;
      }
    }
  }

  async geofenceAlarm(data: any) {
    if (this.into) {
      let audio = new Audio();
      audio.src = './assets/audio/single-bell-g.wav';
      audio.load();
      audio.play();

      const toast = await this.toastController.create({
        message: `Nuevo cruce de geocerca ${data.body} - ${data.username}`,
        duration: 5000,
        id: data.key,
        buttons: [
          {
            text: 'Cerrar',
            icon: 'close-circle-outline',
            role: 'cancel'
          },
          {
            text: 'Ver detalle',
            icon: 'map-outline',
            role: 'open',
            handler: () => {
              this.router.navigate([`/person/${data.uid}`]);
            }
          }
        ]
      });

      await toast.present();
    }
  }

  async subscribeFollow(group: boolean) {
    const d = new Date();
    d.setDate(d.getDate() - 2);
    this.activeFollows = false;
    this.follows = [];

    let follows;
    if (group) {
      follows = this.afs.collection<any>('follows', ref =>
        ref.where('published', '>=', d).where('group', '==', this.credentialsService.credentials.group)
      );
    } else {
      follows = this.afs.collection<any>('follows', ref => ref.where('published', '>=', d));
    }

    follows.snapshotChanges().subscribe(actions => {
      this.activeFollows = false;
      actions.map(a => {
        let active = false;

        const data = a.payload.doc.data() as any;
        data.key = a.payload.doc.id;

        const circleIndex: number = this.circles.findIndex(x => {
          return x.key === data.circle;
        });

        if (!group || (group && circleIndex > -1)) {
          // Busca si el objeto ya existe antes de meterlo al arreglo
          const indx: number = this.follows.findIndex(x => {
            return x.key === data.key;
          });

          let color = 'primary';

          if (!data.following) {
            color = 'primary';
          }

          if (data.active) {
            color = 'secondary';
          }

          if (data.finished) {
            color = 'ligth';
          }

          data.color = color;

          this.addFollow(data);
          //fol.push({ circle: data['circle'], username: data['username'], date: data['published'].toDate() });
        }
      });

      //console.log(JSON.stringify(fol));

      this.sendFollows.next();
    });
  }

  subscribeEmergency(group: boolean) {
    const time = this.user.role == 'admin' ? 2 : 1;
    const d = new Date();
    d.setDate(d.getDate() - time);
    this.activeEmergencies = false;
    this.emergencies = [];

    let emergencies;
    if (group) {
      emergencies = this.afs.collection<Emergency>('emergencies', ref =>
        ref.where('published', '>=', d).where('group', '==', this.credentialsService.credentials.group)
      );
    } else {
      emergencies = this.afs.collection<Emergency>(
        'emergencies',
        ref => ref.orderBy('published', 'asc').limitToLast(20) /* .where('published', '>=', d) */
      );
    }

    this.emergencySub$ = emergencies.snapshotChanges().subscribe(async actions => {
      this.active = false;
      let actualemergencies: Array<Emergency> = [];

      await actions.map(async a => {
        const data = a.payload.doc.data() as Emergency;
        data.key = a.payload.doc.id;

        const circleIndex: number = this.circles.findIndex(x => {
          return x.key === data.circle;
        });

        if (!group || (group && circleIndex > -1)) {
          let ref = this.afs.doc<Circle>('circles/' + data.circle);
          const cir = await ref.get().toPromise();
          const exists = cir.exists;
          const circle: Circle = exists
            ? cir.data()
            : {
                zone: null
              };

          switch (this.user.role) {
            case 'admin':
              this.addEmergency(data);
              break;
            case 'guardian':
              const index: number = this.guardian.groups
                ? this.guardian.groups.findIndex((x: string) => {
                    return x === data.circle;
                  })
                : -1;

              if (index != -1 || !this.guardian.groups) {
                //show inside and outside
                this.addEmergency(data);
              }
              break;
            case 'driver':
              const idx: number = this.guardian.groups
                ? this.guardian.groups.findIndex((x: string) => {
                    return x === data.circle;
                  })
                : -1;

              if (circle.zone && (idx != -1 || !this.guardian.groups)) {
                const emZone = geohash.encode(data.coord.latitude, data.coord.longitude, 6);

                if (circle.zone != emZone) {
                  //show outside only
                  this.addEmergency(data);
                }
              }
              break;
          }
        }

        //actualemergencies.push(data);
      });

      /*       for (let index = 0; index < this.emergencies.length; index++) {
        const element = this.emergencies[index];

        const idx: number = actualemergencies.findIndex(x => {
          return element.key == x.key;
        });

         if (idx < 0) {
          delete this.emergencies[index];
        } 
      } */

      this.sendEmergencies.next();
    });
  }

  async searchCharacter(name: string) {
    let namez = name.toUpperCase();
    let data = this.afd
      .list('users', ref =>
        ref
          .orderByChild('username')
          .startAt(namez)
          .endAt(namez + '\uf8ff')
          .limitToFirst(10)
      )
      .snapshotChanges()
      .pipe(take(1))
      .subscribe(users => {
        let tmpUsers: any[] = [];
        for (let index = 0; index < users.length; index++) {
          const element = users[index];
          const data: any = element.payload.val();
          data.key = element.key;

          if (environment.GROUP == '' && this.credentialsService.credentials.group == '') {
            tmpUsers.push(data);
          } else {
            const circleIndex: number = this.circles.findIndex(x => {
              return x.key === data.cid;
            });

            if (circleIndex > -1) {
              tmpUsers.push(data);
            }
          }
        }
        this.users = tmpUsers;
        //this.loading.dismiss();
      });
  }

  async showAlert(title: string, message: string) {
    let alert = await this.alertController.create({
      header: title,
      message: message,
      buttons: ['Aceptar']
    });

    alert.present();
    setTimeout(() => {
      alert.dismiss();
    }, 10000);
  }

  async actionShowAlert(title: string, message: string, type: string) {
    let alert = await this.alertController.create({
      header: title,
      subHeader: message,
      buttons: [
        {
          text: 'Aceptar',
          handler: () => {
            alert.dismiss().then(() => {
              /* this.openNativeSettings
            .open(type)
            .then(() => {})
            .catch((error) => {
              console.log("cannot open location");
            }); */
            });
          }
        }
      ]
    });

    alert.present();
  }

  async userModal() {
    const modal = await this.modalController.create({
      component: UserComponent,
      componentProps: {
        action: 'create'
      },
      backdropDismiss: false
    });
    return await modal.present();
  }

  async userSearch() {
    const modal = await this.modalController.create({
      component: UsersearchComponent,
      backdropDismiss: true
    });
    return await modal.present();
  }

  async circleSearch() {
    const modal = await this.modalController.create({
      component: CirclesearchComponent,
      backdropDismiss: true
    });
    return await modal.present();
  }

  async copyInputMessage(copyText: string) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', copyText);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    const toast = await this.toastController.create({
      message: 'El texto fue copiado al portapapeles.',
      duration: 1500
    });
    toast.present();
  }

  getItems(ev: any) {
    const val: string = ev.target.value;
    if (val == '') {
      this.tmpPhones = [];
      this.search = false;
      return;
    }

    this.search = true;

    if (this.search_timeout) {
      clearTimeout(this.search_timeout);
    }

    this.search_timeout = setTimeout(async () => {
      let tmpPhones: any[] = [];
      let validPhones: any[] = [];
      let invalidPhones: any[] = [];

      this.dataService.numbers.forEach(phone => {
        if (phone.phone.search(val) !== -1) {
          const now = firebase.firestore.Timestamp.now();
          if (now >= phone.dateStart && now <= phone.dateEnd) {
            phone.status = true;
            validPhones.push(phone);
          } else {
            phone.status = false;
            invalidPhones.push(phone);
          }
        }
      });

      this.tmpPhones = validPhones.concat(invalidPhones);
    }, 1000);
  }

  showDiatel() {
    this.presentDiatel = !this.presentDiatel;
  }

  async warroomInfo() {
    try {
      const info = await this.alertController.create({
        message:
          '<h2 class="headalert">Versión War Room</h2>' +
          '<ion-icon slot="icon-only" class="infwindow" name="person-circle-outline"></ion-icon> ' +
          this.user.username +
          ' <br><br>' +
          '<ion-icon slot="icon-only" class="infwindow" name="cube-outline"></ion-icon> ' +
          this.version
      });

      await info.present();
    } catch (error) {}
  }

  validateGroupAccess(groups: Array<string>) {
    let valid = false;
    console.log(groups);

    if (environment.GROUP == '' && this.credentialsService.credentials.group == '') {
      valid = true;
    } else {
      if (groups) {
        const idx: number = groups.findIndex(x => {
          return x == environment.GROUP || x == this.credentialsService.credentials.group;
        });
        console.log(idx);
        if (idx > -1) {
          valid = true;
        }
      }
    }
    console.log(valid);

    if (!valid) {
      this.router.navigate(['/home/']);
    }

    return valid;
  }

  listView(view: boolean) {
    this.switch = view;
  }

  //Listen to the "enter" keyboard press event when the alert window (for exec or tmux) is open
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (this.alert) {
      const okButton: any = document.querySelectorAll('ion-alert button')[1];
      okButton.click();
      this.alert.dismiss();
      this.alert = null;
    }
  }
}
