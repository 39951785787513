import { Logger, I18nService, AuthenticationService, untilDestroyed, CredentialsService } from '@app/core';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { ApiService } from '@app/shared/services/api/api.service';
import { DataService } from '../services/data/data.service';
import { environment } from '@env/environment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  @Input() user: any;
  @Input() action: string;

  public editForm: FormGroup;
  public createForm: FormGroup;

  constructor(
    private router: Router,
    public modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    public afd: AngularFireDatabase,
    private afs: AngularFirestore,
    public formBuilder: FormBuilder,
    public credentialsService: CredentialsService,
    public apiService: ApiService,
    public dataService: DataService
  ) {}

  ngOnInit(): void {
    if (this.action == 'edit') {
      this.editForm = this.formBuilder.group({
        email: [
          this.user.email ? this.user.email : '',
          Validators.compose([Validators.required, Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')])
        ],
        username: [
          this.user.username ? this.user.username : '',
          Validators.compose([Validators.minLength(3), Validators.required])
        ],
        //cid: [this.user.cid ? this.user.cid : '', Validators.compose([Validators.minLength(2), Validators.required])],
        phone: [this.user.principalPhone ? this.user.principalPhone : '']
        //canRequest: [this.user.canRequest ? this.user.canRequest : '', Validators.compose([Validators.required])],
        //canBeRequested: [this.user.canBeRequested ? this.user.canBeRequested : '',Validators.compose([Validators.required])]
      });
    } else {
      const group =
        environment.GROUP != ''
          ? environment.GROUP
          : this.credentialsService.credentials.group
          ? this.credentialsService.credentials.group
          : '';
      this.createForm = this.formBuilder.group({
        email: [
          '',
          Validators.compose([Validators.required, Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')])
        ],
        pwd: [this.random(), Validators.compose([Validators.minLength(6), Validators.required])],
        username: ['', Validators.compose([Validators.minLength(6), Validators.required])],
        /* cid: [
          group != '' ? this.capitalize(group) : '',
          Validators.compose([Validators.minLength(2), Validators.required])
        ], */
        phone: ['']
        //canRequest: [false, Validators.compose([Validators.required])],
        //canBeRequested: [false, Validators.compose([Validators.required])]
      });
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

  capitalize(data: any) {
    return data.toString().toUpperCase();
  }

  stopPropagationEvt(event: { stopPropagation: () => void }) {
    event.stopPropagation();
  }

  async copyInputMessage(copyText: string) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', copyText);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    const toast = await this.toastController.create({
      message: 'Información copiada al portapapeles.',
      duration: 1500
    });
    toast.present();
  }

  async save() {
    let data = this.editForm.value;
    data.username = this.capitalize(data.username);
    data.warroom = true;
    //data.cid = this.capitalize(data.cid);

    /* if (environment.GROUP != '' || this.credentialsService.credentials.group != '') {
      const group = environment.GROUP != '' ? environment.GROUP : this.credentialsService.credentials.group;
      const groupName = group.toUpperCase();
      const datacid = data.cid;
      if (datacid != this.user.cid && datacid != groupName) {
        data.cid = `${groupName}-${datacid}`;
        data.groupName = group;
        redirect = true;
      }
    } */

    //if (data.username != this.user.username) redirect = true;

    if (data.username == this.user.username) delete data.username;

    try {
      await this.apiService.updateUser(this.user.uid, data);
    } catch (error) {}

    // if (redirect) {
    this.router.navigate([`/person/${this.user.uid}`]);
    //}

    await this.modalController.dismiss();
  }

  async create() {
    let data = this.createForm.value;
    data.username = this.capitalize(data.username);
    //data.cid = this.capitalize(data.cid);

    if (environment.GROUP != '' || this.credentialsService.credentials.group != '') {
      const group = environment.GROUP != '' ? environment.GROUP : this.credentialsService.credentials.group;
      const groupName = group.toUpperCase();
      if (data.cid != groupName) {
        data.cid = `${groupName}-${data.cid}`;
      } else {
        data.cid = `${groupName}`;
      }

      data['group'] = group;
    }

    let response: any = null;
    try {
      response = await this.apiService.createUser(data);
    } catch (error) {}

    if (response) {
      if (response['id']) {
        this.router.navigate([`/circle/${response['id']}`]);
      }
    }

    await this.modalController.dismiss();
  }

  async deleteConfirmation() {
    let keywordData: any = null;

    if (environment.GROUP == '' && this.credentialsService.credentials.group == '') {
      keywordData = await this.afd
        .object('constants/callc_magic')
        .valueChanges()
        .pipe(take(1))
        .toPromise();
    } else {
      const group = environment.GROUP != '' ? environment.GROUP : this.credentialsService.credentials.group;
      const data: any = await this.afs
        .doc(`group/${group}`)
        .valueChanges()
        .pipe(take(1))
        .toPromise();

      keywordData = data['callc_magic'];
    }

    const alert = await this.alertController.create({
      header: 'Confirmación',
      inputs: [
        {
          name: 'keyword',
          type: 'password',
          placeholder: 'Palabra clave'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Verificar',
          handler: data => {
            let keyword = '';
            keyword = data.keyword;
            if (keyword.toLowerCase() === keywordData) {
              this.delete();
            } else {
              this.presentAlert('Palabra clave incorrecta', 'Intenta de nuevo');
            }
          }
        }
      ]
    });
    alert.present();
  }

  async delete() {
    try {
      await this.apiService.deleteUser(this.user.uid);
    } catch (error) {}

    this.router.navigate(['/home']);

    await this.modalController.dismiss();
  }

  random() {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  async presentAlert(message: string, submessage: string) {
    const alert = await this.alertController.create({
      header: message,
      subHeader: submessage,
      buttons: ['Aceptar']
    });

    await alert.present();

    setTimeout(() => {
      alert.dismiss();
    }, 3000);
  }
}
